export const calculateVpdDiscount = (price: number, newPrice: number) => {
  if (price && price > 0 && newPrice && newPrice > 0) {
    return price - newPrice;
  } else {
    return 0;
  }
};

export const calculateVpdNewPrice = (price: number, discount: number) => {
  if (price && price > 0 && discount && discount > 0) {
    return price - price * (discount / 100);
  } else {
    return 0;
  }
};
